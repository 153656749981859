import { ProductVariant } from "#iso/lib/shopify";
import { mdiClose, mdiPlayCircle } from "@mdi/js";
import { animated, useSpring, useSpringRef } from "@react-spring/web";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "preact/compat";
import {
  getLocalePathname,
  useLocaleMarket,
  useLocalePathname,
} from "../../lib/pathnames.jsx";
import { getWidgetPaths } from "../../lib/shapes.jsx";
import { Product, Products, formatPrice } from "../../lib/shopify.js";
import ProductVariantPhoto from "../product_variant_photo/index.jsx";
import "./style.scss";
import { SlideContext } from "../slide/index.jsx";
import * as zaraz from "../../lib/zaraz.jsx";

export const useTimelineAlternatingAnimation = (
  options: {
    className?: string;
    progress?: number;
    duration?: number;
    speedup?: number;
    target?: number | null;
    alternate?: boolean;
    between?: boolean;
    disabled?: boolean;
    onChange?: (index: number) => void;
    onStateChange?: (state: "playing" | "paused") => void;
  } = {}
) => {
  const item = useContext(SlideContext);

  const {
    className = "step",
    progress = 0,
    duration = 15000,
    speedup = 3,
    target = 1,
    alternate = false,
    between = false,
    disabled = !item.isFocused,
  } = options;

  const ref = useRef({
    state: "paused" as "playing" | "paused",
    position: progress * duration,
    frame: 0,
    time: 0,
    direction: 1,
    element: null as HTMLDivElement | null,
    steps: [] as HTMLDivElement[],
    currentIndex: 0,
    disabled,
    target,
    className,
    duration,
    progress,
    alternate,
  });

  ref.current.disabled = disabled;

  const onFrame: FrameRequestCallback = useCallback((time) => {
    const total = ref.current.steps.length - (between ? 1 : 0);
    ref.current.time ||= time;
    ref.current.position =
      ref.current.position +
      (time - ref.current.time) *
        (ref.current.direction * (ref.current.target == null ? 1 : speedup));
    ref.current.progress = ref.current.position / ref.current.duration;
    const lower = ref.current.target ?? 0;
    const upper = ref.current.target ?? 1;

    if (
      ref.current.direction > 0
        ? ref.current.progress >= upper
        : ref.current.progress <= lower
    ) {
      ref.current.progress = ref.current.direction > 0 ? upper : lower;
      if (ref.current.target != null) {
        ref.current.progress = ref.current.target;
        //ref.current.direction = 1;
        pause();
      } else {
        ref.current.direction *= -1;
      }
    }
    ref.current.position = ref.current.progress * ref.current.duration;
    ref.current.time = time;

    var currentIndex = Math.floor(ref.current.progress * total);
    if (
      ref.current.direction == -1 &&
      currentIndex < total &&
      between &&
      ref.current.state == "playing"
    ) {
      currentIndex++;
    }
    ref.current.steps.forEach((step, index) => {
      if (currentIndex == index && ref.current.currentIndex !== currentIndex) {
        ref.current.currentIndex = currentIndex;
        options.onChange?.(index);
      }
    });
    ref.current.currentIndex = currentIndex;
    ref.current.element!.style.setProperty(
      "--progress",
      String(ref.current.progress)
    );
    if (ref.current.state == "playing") schedule();
  }, []);

  function schedule() {
    cancelAnimationFrame(ref.current.frame);
    ref.current.frame = requestAnimationFrame(onFrame);
  }

  function pause() {
    ref.current.state = "paused";
    ref.current.element?.classList.add("paused");
    options.onStateChange?.(ref.current.state);
    cancelAnimationFrame(ref.current.frame);
  }

  function play() {
    ref.current.time = 0;
    ref.current.state = "playing";
    ref.current.element?.classList.remove("paused");
    options.onStateChange?.(ref.current.state);
    schedule();
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          play();
        } else {
          pause();
        }
      },
      { threshold: 0.1 }
    );

    //console.log("observe", ref.current.disabled);
    setTimeout(
      () => {
        if (ref.current.element && !ref.current.disabled) {
          observer.observe(ref.current.element);
        }
      },
      location.search.includes("stop") ? 100000 : 1000
    );
    ref.current.steps = Array.from(
      ref.current.element?.querySelectorAll("." + className) || []
    ) as HTMLDivElement[];

    return () => {
      if (ref.current.element) {
        observer.unobserve(ref.current.element);
      }
      pause();
      cancelAnimationFrame(ref.current.frame);
    };
  }, [ref.current.disabled]);

  const setTarget = (index: number | null) => {
    const total = ref.current.steps.length - (between ? 1 : 0);
    if (index == null) {
      ref.current.target = null;
      ref.current.direction = 1;
    } else {
      ref.current.target = Math.max(
        0,
        (index + (between ? 0 : 1)) / total - (between ? 0 : 0.001)
      );
      ref.current.direction =
        ref.current.progress >= ref.current.target ? -1 : 1;
    }
    play();
  };

  return { timeline: ref.current, setTarget, play, pause };
};

function Summary({
  name,
  id,
  variants,
  isFocused,
  focusedVariant,
  focusedProduct,
  setFocusedProduct,
  setFocusedVariant,
  children,
  price,
  ...props
}: {
  name: string;
  id: string;
  isFocused: boolean | null;
  variants: Product["variants"];
  focusedVariant: string | undefined;
  focusedProduct: string | null;
  setFocusedProduct: Dispatch<SetStateAction<string | null>>;
  setFocusedVariant: Dispatch<SetStateAction<string | null>>;
  children: any;
  price: string;
} & React.HTMLAttributes<HTMLDivElement>) {
  const [springs, springApi] = useSpring(
    () => ({
      from: {
        "--this-product-focus-progress": 0,
        "--this-product-not-focus-progress": 0,
      },
      config: {
        tension: 100,
        friction: 30,
      },
    }),
    []
  );

  useEffect(() => {
    springApi.start({
      "--this-product-focus-progress": isFocused == true ? 1 : 0,
      "--this-product-not-focus-progress": isFocused == false ? 1 : 0,
      delay: () => 200,
    });
  }, [isFocused]);

  return (
    /* @ts-ignore */
    <animated.div
      {...props}
      {...{ style: springs }}
      className={`information ${props.className} ${
        isFocused == null ? "" : isFocused ? "focused" : "not-focused"
      }`}
    >
      <div className="more">
        <div className="copy no-variant" hidden={!!focusedVariant}>
          <p>
            <span className="emphasis">Select a hue</span> that resonates with
            your soul. Four choices is four destinies.
          </p>
          <p>
            <span className="emphasis">Click pictures</span> to explore the
            natural mysteries of onyx.
          </p>
        </div>
        {variants.map((v) => {
          return (
            <div
              className="copy"
              hidden={focusedVariant != v.handle}
              key={v.handle}
            >
              {useMemo(
                () => (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: String(v.colorDescription || "").replace(
                        /\*(.*?)\*/g,
                        "<strong>$1</strong>"
                      ),
                    }}
                  ></p>
                ),
                []
              )}
            </div>
          );
        })}
      </div>
      <div className="buttons">
        <button
          className={`button next`}
          onClickCapture={(e) => {
            const target = e.target as HTMLElement;
            if (target.closest(".button")) {
              if (focusedProduct == id) {
                setFocusedProduct(null);
                setFocusedVariant(null);
              } else {
                setFocusedProduct(id);
              }
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <span>Choose color</span>
        </button>
        <button
          className={`button secondary cancel`}
          onClickCapture={(e) => {
            const target = e.target as HTMLElement;
            if (target.closest(".button")) {
              if (focusedProduct == id) {
                setFocusedProduct(null);
                setFocusedVariant(null);
              } else {
                setFocusedProduct(id);
              }
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <svg>
            <path d={mdiClose} />
          </svg>
          <span>Change size</span>
        </button>
      </div>
      <span className="price">{price}</span>
      <div className="about">
        <h3>{name}</h3>
        {children}
      </div>
    </animated.div>
  );
}
export function ListOnyx({
  products,
  allProducts,
  activeId,
  loading,
}: {
  allProducts: Products;
  products: Products;
  activeId?: string;
  loading?: string;
}) {
  const refs = useRef<{ hover?: ReturnType<typeof setTimeout> }>({});
  const market = useLocaleMarket();
  const pathname = useLocalePathname();
  const paths = getWidgetPaths({
    currency: market == "Worldwide" ? "USD" : "IDR",
  });
  const [focusedProduct, setFocusedProduct] = useState(
    () => null as string | null
  );

  const [focusedVariant, setFocusedVariantRaw] = useState<string | null>(
    activeId || null
  );
  function setFocusedVariant(id: string | null) {
    const collectionId = pathname.split("/")[1];
    const newPath = getLocalePathname(
      id ? "/" + collectionId + "/" + id : "/" + collectionId + "/"
    );
    window.history.replaceState(window.history.state, "", newPath);
    setFocusedVariantRaw(id);
  }
  const [mode, setMode] = useState(() => "Mix" as "Dark" | "Light" | "Mix");

  const ref = useSpringRef();

  const [isHydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    if (activeId) {
      const activeVariant = products
        .map((product) => product.variants.find((v) => v.title == activeId))
        .filter(Boolean)[0];
      setFocusedVariant(activeVariant?.handle ?? null);
    }
  }, [activeId]);

  useEffect(() => {
    if (focusedProduct) {
      const product = allProducts.find((p) => p.handle == focusedProduct);
      const variant = focusedVariant
        ? product?.variants.find((v) => v.handle == focusedVariant)
        : null;
      if (product && variant)
        zaraz.ecommerce("Product Viewed", zaraz.getProduct(variant, product));
    }
  }, [focusedProduct, focusedVariant]);

  const [springs, springApi] = useSpring(
    () => ({
      from: {
        "--product-focus-progress": 0,
        "--product-focus-delayed-progress": 0,
      },
      delay(key) {
        return key == "--focus-delayed-progress" ? 400 : 0;
      },
    }),
    []
  );

  const { timeline, setTarget, play } = useTimelineAlternatingAnimation({
    className: "mode",
    progress: 0.5,
    speedup: 15,
    alternate: true,
    duration: 15000,
    between: true,
    target: null,
    onChange: (index) => {
      if (
        (timeline.target != null ? Math.floor(timeline.target * 2) : index) ==
        index
      ) {
        setMode((["Dark", "Mix", "Light"] as const)[index]);
      }
    },
  });

  useEffect(() => {
    springApi.start({
      "--product-focus-progress": focusedProduct ? 1 : 0,
      "--product-focus-delayed-progress": focusedProduct ? 1 : 0,
    });
  }, [focusedProduct, focusedVariant]);

  /*
  useEffect(() => {
    setTimeout(() => {
      products
        .flatMap((p) => {
          return p.variants.flatMap((v) => {
            return v.thumbnails.filter((t) => t.url.includes(p.size));
          });
        })
        .forEach((thumbnail) => {
          new window.Image().src = thumbnail.url;
        });
    }, 5000);
  }, [products]); */

  const photos = products.flatMap((p: Products[number]) => {
    return (
      p.variants.map((v: ProductVariant, index) => {
        function getScore(src: string, ...substrings: string[]) {
          return substrings.map((s) => src.includes(s)).filter(Boolean).length;
        }
        return (
          <ProductVariantPhoto
            className="cropped"
            loading={loading}
            variant={v}
            badge={v.badge || p.badge}
            key={v.handle}
            isFocused={
              !focusedVariant
                ? null
                : focusedProduct == p.handle && focusedVariant == v.handle
            }
            image={
              v.thumbnails.sort((a, b) => {
                return (
                  getScore(b.url, v.color, p.size, mode) -
                  getScore(a.url, v.color, p.size, mode)
                );
              })[0] || {
                url: "https://placehold.co/400x400",
                width: 400,
                height: 400,
              }
            }
            alt={v.title}
            size={p.size}
            color={v.color}
            index={index}
            product={p.handle}
            products={allProducts}
            preloads={
              !isHydrated
                ? []
                : (["Dark", "Light", "Mix"].map(
                    (mode) =>
                      v.thumbnails.sort((a, b) => {
                        return (
                          getScore(b.url, v.color, p.size, mode) -
                          getScore(a.url, v.color, p.size, mode)
                        );
                      })[0]
                  ) as any as {
                    url: string;
                    width: number;
                    height: number;
                  }[])
            }
          />
        );
      }) || []
    );
  });

  function playTarget(index: number) {
    setTarget(
      Math.floor(timeline.progress * 2) == index && timeline.state != "playing"
        ? null
        : index
    );
  }

  return (
    <>
      <animated.div
        className={`screen products  top-screen`}
        data-mode={mode}
        data-product-focused={focusedProduct}
        data-product-focused-variant={focusedVariant}
        style={{
          "--slide-current-offset": "40px",
          "--slide-collapsed-offset": "calc(40px + var(--slide-overlap) * .8)",
          "--slide-focused-offset": "-50px",
          ...springs,
          /* @ts-ignore */
          "--bubble-left-path": `path("${paths.bubbleLeft}")`,
          "--bubble-right-path": `path("${paths.bubbleRight}")`,
        }}
        onMouseLeave={(e) => {
          clearTimeout(refs.current.hover);
          if (!("ontouchstart" in document.documentElement)) {
            refs.current.hover = setTimeout(() => {
              setFocusedProduct(null);
              setFocusedVariant(null);
            }, 150);
          }
        }}
        onMouseDownCapture={(e) => {
          const product = (e.target as HTMLElement).closest(
            "[data-product-variant]"
          );
          if (product) {
            if (
              focusedProduct != product.getAttribute("data-product") ||
              focusedVariant != product.getAttribute("data-product-variant")
            ) {
              clearTimeout(refs.current.hover);
              setFocusedProduct(product.getAttribute("data-product"));
              setFocusedVariant(product.getAttribute("data-product-variant"));
              if (!product.querySelector(".buyer.active")) {
                e.preventDefault();
                e.stopPropagation();
              }
            }
          }
        }}
        onMouseMove={(e) => {
          if ("ontouchstart" in document.documentElement) return;

          clearTimeout(refs.current.hover);
          const product = (e.target as HTMLElement).closest(
            "[data-product-variant]"
          );
          refs.current.hover = setTimeout(() => {
            if (product) {
              setFocusedProduct(product.getAttribute("data-product"));
              setFocusedVariant(product.getAttribute("data-product-variant"));
            }
          }, 350);
          if (!product) {
            setFocusedProduct(null);
            setFocusedVariant(null);
          }
        }}
      >
        <div
          className="range"
          ref={(e) => {
            timeline.element = e;
          }}
        >
          <svg
            viewBox="0 0 24 24"
            className={`mode dark ${mode == "Dark" ? "current" : ""}`}
            onClick={() => {
              playTarget(0);
            }}
          >
            <mask
              id="mask0_1_8"
              style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="-1"
              y="-3"
              width="34"
              height="31"
            >
              <rect
                x="-0.14975"
                y="-2.7257"
                width="33.1344"
                height="30.2445"
                fill="white"
              />
              <circle cx="20.9987" cy="11.9592" r="9.45315" fill="black" />
            </mask>
            <g mask="url(#mask0_1_8)">
              <circle
                cx="11.5456"
                cy="11.9592"
                r="9.45315"
                stroke="white"
                strokeWidth="1.5"
              />
            </g>
            <path className="play" d={mdiPlayCircle} />
          </svg>
          <svg
            viewBox="0 0 24 24"
            className={`mode mix ${mode == "Mix" ? "current" : ""}`}
            onClick={() => {
              playTarget(1);
            }}
          >
            <mask
              id="mask0_1_64"
              style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="-10"
              y="-10"
              width="36"
              height="43"
            >
              <rect
                width="33.1344"
                height="30.2445"
                transform="matrix(-1 0 0 1 23.2926 -2.7257)"
                fill="black"
              />
              <circle
                cx="8.69748"
                cy="8.69748"
                r="8.69748"
                transform="matrix(-1 0 0 1 24.8764 3.26173)"
                fill="white"
              />
              <ellipse
                cx="7.07639"
                cy="21.1803"
                rx="7.07639"
                ry="21.1803"
                transform="matrix(-1 0 0 1 25.5441 -9.4946)"
                fill="white"
              />
              <circle
                cx="8.69748"
                cy="8.69748"
                r="8.19748"
                transform="matrix(-1 0 0 1 18.9264 3.26173)"
                stroke="red"
                fill="transparent"
              />
            </mask>
            <g mask="url(#mask0_1_64)">
              <path d="M0.700005 12L3.4 10.4L1.3 7.7L4.8 7.2L4 4.1L6.8 4.9L7.3 1.5L10 3.6L12 0.2L14 3.6L16.7 1.5L17.1 4.7L20.2 3.9L19.3 7.2L22.7 7.7L20.6 10.4L23.3 12V12.1L20.6 13.6L22.7 16.4L19.3 16.8L20.2 20.1L17.1 19.3L16.7 22.6L13.9 20.4L12 23.7L10.1 20.4L7.3 22.6L6.8 19.2L4 20L4.8 16.9L1.3 16.4L3.4 13.6L0.700005 12.1V12Z" />
            </g>
            <circle
              cx="8.69748"
              cy="8.69748"
              r="8.19748"
              transform="matrix(-1 0 0 1 18.9264 3.26173)"
              stroke="red"
              fill="transparent"
            />

            <path className="play" d={mdiPlayCircle} />
          </svg>
          <svg
            viewBox="0 0 24 24"
            className={`mode light ${mode == "Light" ? "current" : ""}`}
            onClick={() => {
              playTarget(2);
            }}
          >
            <path d="M0.700005 12L3.4 10.4L1.3 7.7L4.8 7.2L4 4.1L6.8 4.9L7.3 1.5L10 3.6L12 0.2L14 3.6L16.7 1.5L17.1 4.7L20.2 3.9L19.3 7.2L22.7 7.7L20.6 10.4L23.3 12V12.1L20.6 13.6L22.7 16.4L19.3 16.8L20.2 20.1L17.1 19.3L16.7 22.6L13.9 20.4L12 23.7L10.1 20.4L7.3 22.6L6.8 19.2L4 20L4.8 16.9L1.3 16.4L3.4 13.6L0.700005 12.1V12Z" />
            <path className="play" d={mdiPlayCircle} />
          </svg>
        </div>
        <div className="clock"></div>
        <div className="product-list onyx-list " data-count="4">
          {photos}
          <div className="directions content">
            {products.map((product, index) => {
              return (
                <Summary
                  key={product.id}
                  isFocused={
                    focusedProduct ? focusedProduct == product.handle : null
                  }
                  variants={product.variants}
                  name={product.title}
                  id={product.handle}
                  price={formatPrice(product.priceRange.minVariantPrice)}
                  className={product.size == "Medium" ? "right" : "left"}
                  focusedProduct={focusedProduct}
                  focusedVariant={focusedVariant || undefined}
                  setFocusedProduct={setFocusedProduct}
                  setFocusedVariant={setFocusedVariant}
                >
                  {" "}
                  {useMemo(
                    () => (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.specifications,
                        }}
                      ></div>
                    ),
                    []
                  )}
                </Summary>
              );
            })}
          </div>
        </div>
      </animated.div>
    </>
  );
}
