import { mdiChevronDown, mdiClose, mdiEarth, mdiGlobeLight } from "@mdi/js";
import { getLocaleFromContext } from "../../lib/locales.js";
import {
  useLocaleCountry,
  useLocaleCurrency,
  useLocaleLanguage,
  useLocaleMarket,
  useLocalePathname,
} from "../../lib/pathnames.jsx";
import "./style.scss";
import { useState } from "preact/hooks";
import { useEffect } from "react";

export default function Region(props?: { key?: string; slot?: string }) {
  const pathname = useLocalePathname();
  const country = useLocaleCountry();
  const language = useLocaleLanguage();
  const [open, setOpen] = useState(false);
  const market = useLocaleMarket();
  const currency = useLocaleCurrency();
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      setOpen((open) => {
        if (
          !open ||
          !document
            .querySelector("#select-country")
            .contains(e.target as HTMLElement)
        ) {
          if (open) {
            e.preventDefault();
            e.stopPropagation();
          }
          return false;
        } else {
          return true;
        }
      });
    };
    document.body.addEventListener("click", onClick, true);
    return () => {
      document.body.removeEventListener("click", onClick, true);
    };
  }, []);
  return (
    <>
      <button
        class="region-picker-button"
        id="select-country-button"
        onClickCapture={(e) => {
          const pos = (
            document.querySelector("#select-country-button")! as HTMLElement
          ).getBoundingClientRect();
          setOpen(!open);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <svg viewBox="0 0 24 24">
          <path d={mdiChevronDown} />
        </svg>
        <p>
          {market}, {currency}
        </p>
        <h2>{language == "EN" ? "English" : "Bahasa"}</h2>
      </button>
      <div id="select-country" hidden={!open}>
        <button class="close button" onClick={() => setOpen(false)}>
          <svg>
            <path d={mdiClose} />
          </svg>
        </button>
        <div>
          <label htmlFor="select-language">Language</label>
          <label className="radio">
            <input
              type="radio"
              name="language"
              value="EN"
              checked={language === "EN"}
            />
            English
          </label>
          <label className="radio">
            <input
              type="radio"
              name="language"
              value="ID"
              checked={language === "ID"}
            />
            Bahasa Indonesia
          </label>
        </div>
        <div>
          <label htmlFor="select-market">Market</label>
          <label className="radio">
            <input
              type="radio"
              name="market"
              value="US"
              checked={country === "US"}
            />
            Worldwide (USD)
          </label>
          <label className="radio">
            <input
              type="radio"
              name="market"
              value="ID"
              checked={country === "ID"}
            />
            Indonesia (IDR)
          </label>{" "}
        </div>
        <button
          id="country-button"
          className="button next primary"
          onClickCapture={(e) => {
            document.documentElement.classList.add("loading");
            (e.target as HTMLElement).classList.add("working");
            const locale = getLocaleFromContext({
              languageCode:
                (
                  document.querySelector(
                    'input[name="language"]:checked'
                  ) as HTMLInputElement
                )?.value || "",
              countryCode:
                (
                  document.querySelector(
                    'input[name="market"]:checked'
                  ) as HTMLInputElement
                )?.value || "",
            });
            location.href = `/${locale}${pathname}${location.search}`;
          }}
        >
          <span>Apply changes</span>
        </button>
      </div>
    </>
  );
}
