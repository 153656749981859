import { useEffect } from "react";

export function useObserveAndIntersectLazyImages(root: HTMLElement) {
  useEffect(() => {
    if (!root) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.debug(entry.target, "preloading");
            const img = entry.target as HTMLImageElement;
            img.loading = "eager";
            observer.unobserve(img);
          }
        });
      },
      { root, rootMargin: "0px 0px 100% 0px" }
    );

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLImageElement && node.loading === "lazy") {
              observer.observe(node);
            }
          });
          mutation.removedNodes.forEach((node) => {
            if (node instanceof HTMLImageElement && node.loading === "lazy") {
              observer.unobserve(node);
            }
          });
        }
      });
    });

    mutationObserver.observe(root, {
      childList: true,
      subtree: true,
    });

    const images = root.querySelectorAll('img[loading="lazy"]');
    images.forEach((img) => observer.observe(img));

    return () => {
      observer.disconnect();
      mutationObserver.disconnect();
    };
  }, [root]);
}
