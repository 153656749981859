import {
  useLocaleCountry,
  useLocaleLanguage,
  useShopifyContext,
} from "../../lib/pathnames.jsx";
import {
  CartData,
  CartLine,
  CheckoutVariant,
  addCartLines,
  createCart,
  getCart,
  shopifyQuery,
  updateCartLines,
} from "../../lib/shopify.js";
import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "preact/compat";

export interface CartContextProps {
  cart: CartData | null;
  lines: CartLine[];
  addToCart: (item: CheckoutVariant) => void;
  totalQuantity: number;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export default function CartProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [, render] = useReducer((s) => s + 1, 0);
  const ref = useRef<{
    cart: CartData | null;
    lastUpdate: Date | null;
    lastAction: Promise<any>;
    optimisticCart: CartData | null;
  }>({
    cart: null,
    lastUpdate: null,
    lastAction: Promise.resolve(),
    optimisticCart: null,
  });
  const context = useShopifyContext();

  useLayoutEffect(() => {
    if (typeof localStorage != "undefined") {
      var cachedCart = localStorage.getItem("cart");
      if (cachedCart) {
        const parsed = JSON.parse(cachedCart) as CartData;
        if (parsed?.id && parsed?.lines) {
          applyCart(parsed);
        } else {
          cachedCart = null;
        }
      }
      if (cachedCart) {
        getCart(JSON.parse(cachedCart)?.id)
          .catch((e) => {
            console.log("Cart error", e);
            return null;
          })
          .then((cart) => {
            if (JSON.stringify(cart) != cachedCart) applyCart(cart);
          });
      }
    }
  }, []);

  const applyCart = (newCart: CartData | null) => {
    if (JSON.stringify(newCart) != JSON.stringify(ref.current.cart)) {
      if (!newCart) localStorage.removeItem("cart");
      else localStorage.setItem("cart", JSON.stringify(newCart));
      ref.current.cart = newCart;
      render(0);
    }
    return newCart;
  };

  const addCartAction = async (item: CheckoutVariant) => {
    const cart = ref.current.cart;
    const line = cart?.lines.edges.find(
      (e) => e.node.merchandise.id == item.id
    );
    var newCart = cart?.id ? cart : await createCart(context);
    if (line?.node?.id) {
      return updateCartLines(
        newCart?.id,
        [
          {
            id: line.node.id,
            merchandiseId: item.id,
            quantity: line.node.quantity + item.quantity,
          },
        ],
        context
      );
    } else {
      return addCartLines(
        newCart?.id,
        [{ merchandiseId: item.id, quantity: item.quantity }],
        context
      );
    }
  };
  const addToCart = async (item: CheckoutVariant) => {
    const cart = ref.current.optimisticCart || ref.current.cart;
    const line = cart?.lines.edges.find(
      (e) => e.node.merchandise.id == item.id
    );
    const optimisticCart = {
      ...(ref.current.optimisticCart || ref.current.cart),
      lines: {
        edges: (cart?.lines.edges || [])
          .map((e) => {
            return e == line
              ? {
                  node: {
                    ...line.node,
                    quantity: line.node.quantity + item.quantity,
                  },
                }
              : e;
          })
          .concat(
            (line
              ? []
              : [
                  {
                    node: {
                      merchandise: { id: item.id },
                      quantity: item.quantity,
                    },
                  },
                ]) as any as { node: CartLine }[]
          ),
      },
      totalQuantity: (cart?.totalQuantity || 0) + item.quantity,
    } as CartData;
    const lastUpdate = new Date();
    ref.current.lastUpdate = lastUpdate;
    ref.current.optimisticCart = optimisticCart;
    ref.current.lastAction = ref.current.lastAction.then(async () => {
      const serverCart = await addCartAction(item);
      if (ref.current.lastUpdate == lastUpdate) {
        ref.current.optimisticCart = null;
      }
      applyCart(serverCart);
    });
    if (!ref.current.optimisticCart) applyCart(optimisticCart);
    render(0);
  };

  const visibleCart = ref.current.optimisticCart || ref.current.cart;
  return (
    <CartContext.Provider
      value={{
        cart: visibleCart,
        addToCart,
        totalQuantity: visibleCart?.totalQuantity ?? 0,
        lines: visibleCart?.lines.edges.map((c) => c.node) || [],
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export const useCart = (): CartContextProps => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
