import { PageContext } from "vike/types";
import type { Store } from "../../+store.server.js";
import { mdiEmail, mdiFire, mdiInstagram, mdiMapMarker } from "@mdi/js";
import { useLocalePathname } from "../../../lib/pathnames.jsx";
import { Link } from "../../../components/link/index.jsx";

export function SummaryQNA(
  pageContext: PageContext,
  context: { pathname: string }
) {
  const { menus } = pageContext.store as Store;
  const { pathname } = context;
  return (
    <>
      <h2 slot="menu">Q&A</h2>
      <button className={`button next`} slot="details">
        Browse topics
      </button>
      <div slot="children" className="qna">
        {menus
          .filter((m) => m.handle.startsWith("qna-"))
          .map((menu, index) => {
            return (
              <div
                className="column content "
                data-ref={menu.title}
                key={index}
              >
                <h3>{menu.title.substring(4)}</h3>
                {[menu.items.slice(0, 3), menu.items.slice(3)].map(
                  (items, listIndex) => {
                    return items.length == 0 ? null : (
                      <ul key={listIndex}>
                        {items.map((item, itemIndex) => {
                          const icon = item.url.includes("instagram")
                            ? mdiInstagram
                            : item.url.includes("maps.app")
                            ? mdiMapMarker
                            : item.url.includes("mailto")
                            ? mdiEmail
                            : item.url.includes("offers")
                            ? mdiFire
                            : null;
                          const url = item.url.includes("myshopify")
                            ? "/qna/" + item.url.split("/pages/")[1] + "/"
                            : item.url;
                          return (
                            <li
                              className={icon ? "with-icon" : ""}
                              key={itemIndex}
                            >
                              <Link
                                href={url}
                                className={`${
                                  url == pathname ? "active" : "inactive"
                                }`}
                              >
                                {icon && (
                                  <svg
                                    className="icon inline"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d={icon} />
                                  </svg>
                                )}
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}
