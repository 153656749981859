import { useEffect } from "react";
import { Swiper } from "swiper/types";
import * as zaraz from "./zaraz.jsx";

export const Campaigns = [
  {
    id: "0",
    source: "QR",
    campaign: "Packaging",
    content: "Jar Booklet",
    medium: "Offline",
    dialog: "discount",
  },
  {
    id: "1",
    source: "QR",
    campaign: "Packaging",
    content: "Tea Lights Package",
    medium: "Offline",
    dialog: "discount",
  },
  {
    id: "2",
    source: "QR",
    campaign: "Packaging",
    content: "Giveaway Card",
    medium: "Offline",
  },
  {
    id: "5",
    source: "QR",
    campaign: "Packaging",
    content: "Large onyx",
    medium: "Offline",
    dialog: "discount",
  },
  {
    id: "6",
    source: "QR",
    campaign: "Packaging",
    content: "Small onyx",
    medium: "Offline",
    dialog: "discount",
  },
  {
    id: "f1",
    source: "QR",
    campaign: "Guerilla",
    content: "Buy online or in shop",
    medium: "Offline",
    dialog: "shop",
  },
  {
    id: "f2",
    source: "QR",
    campaign: "Guerilla",
    content: "Buy online or at cashier",
    medium: "Offline",
    dialog: "shop",
  },
  {
    id: "fb",
    source: "Facebook",
    campaign: "Ad",
    content: "Banner",
    medium: "CPC",
  },
  {
    id: "ig",
    source: "Instagram",
    campaign: "Ad",
    content: "Banner",
    medium: "CPC",
  },
] as {
  id: string;
  source: string;
  campaign: string;
  content: string;
  medium: string;
  term?: string;
  dialog?: string;
}[];

export function getCampaign(url: string) {
  var campaign = Campaigns.find((c) => url.includes("?" + c.id)) || null;
  if (campaign) {
    const override = url.split("?" + campaign.id + "=")[1]?.split("&")[0];
    if (override) {
      const [content, term] = decodeURIComponent(override).split(/\s*:\s*/);
      if (content) campaign = { ...campaign, content };
      if (term) campaign = { ...campaign, term };
    }
  }

  return campaign;
}

export function trackSwiperEvents(swiper: Swiper) {
  const id = swiper.el.closest(".slide")?.getAttribute("id") || "Page";
  const page = id.charAt(0).toUpperCase() + id.substring(1);
  const section = swiper.el.closest(".section")?.getAttribute("id") || "List";
  const event = page + " " + section;

  swiper.on("slideNextTransitionStart", (e) => {
    zaraz.track(event, {
      event_category: "Slider",
      event_action: "Next",
      event_label: "Slide #" + (swiper.activeIndex + 1),
    });
  });
  swiper.on("slidePrevTransitionStart", (e) => {
    zaraz.track(event, {
      event_category: "Slider",
      event_action: "Prev",
      event_label: "Slide #" + (swiper.activeIndex + 1),
    });
  });
}
