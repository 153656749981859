import type { Collection, Product, ProductVariant } from "./shopify.server.js";
import {
  set as zarazSet,
  ecommerce as zarazCommerce,
  track as zarazTrack,
} from "zaraz-ts";
import type { Product as ZarazProduct } from "zaraz-ts";

export function getProducts(
  variants: ProductVariant[],
  allProducts: Product[],
  collection?: Collection
) {
  return variants.map((variant) => {
    return getProduct(
      variant,
      allProducts.find((p) => p.id == variant.product.id),
      collection
    );
  });
}

export function getProduct(
  variant: ProductVariant,
  product: Product,
  collection: { handle: string } = product.collections[0]
) {
  return {
    sku: variant.sku,
    name: product.title,
    variant: variant.title,
    product_id: product.id,
    category: product.productType,
    price: parseFloat(variant.price.amount),
    value: parseFloat(variant.price.amount),
    imageUrl: product.images[0]?.url,
    url: "/" + collection.handle + "/" + variant.handle,
    currency: variant.price.currencyCode,
  } as ZarazProduct & {
    currency: string;
    value: number;
  };
}
export const ecommerce: typeof zarazCommerce = function (
  eventName: any,
  parameters?: any
) {
  console.info(eventName, parameters);
  return zarazCommerce(eventName, parameters);
};
export const track: typeof zarazTrack = async (...args) => {
  console.info("Event", ...args);
  return zarazTrack(...args);
};

export const set: typeof zarazSet = async (...args) => {
  console.info("Set", args);
  return zarazSet(...args);
};
