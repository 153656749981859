import { ProductVariant, Products } from "../../lib/shopify.jsx";
import { Grid, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductVariantPhoto from "../product_variant_photo/index.jsx";
import "./style.scss";
import { trackSwiperEvents } from "../../lib/campaigns.jsx";

export default function ListNarrow({
  products,
  allProducts,
  activeId,
  loading,
}: {
  products: Products;
  allProducts: Products;
  activeId?: string;
  loading?: string;
}) {
  const variants = products
    .flatMap((product) =>
      product.variants.filter((variant, index) =>
        variant.listAsSeparateProduct || index == 0 ? true : false
      )
    )
    .sort((a, b) => a.orderIndex - b.orderIndex);
  return (
    <>
      <div
        className="screen full top-screen"
        style={{
          /*@ts-ignore*/
          "--slide-current-offset": "-40px",
          "--slide-collapsed-offset": "55px",
          "--slide-focused-offset": "0px",
        }}
      >
        <Swiper
          effect="slide"
          className="list-products"
          pagination={true}
          navigation={true}
          slidesPerView="auto"
          loop={false}
          slidesPerGroup={1}
          spaceBetween={0}
          maxBackfaceHiddenSlides={0}
          grid={{
            rows: 2,
            fill: "row",
          }}
          onSwiper={trackSwiperEvents}
          onRealIndexChange={(swiper) => {
            swiper.el.closest(".swiper-slide")?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
          breakpoints={{
            800: {
              slidesPerView: "auto",
              loop: false,
              grid: {
                rows: variants.length < 4 ? 1 : 2,
                fill: "row",
              },
            },
            1200: {
              slidesPerView: "auto",
              loop: false,
              grid: {
                rows: variants.length < 4 ? 1 : 2,
                fill: "row",
              },
            },
          }}
          modules={[Scrollbar, Navigation, Grid, Pagination]}
        >
          {variants.map((variant, index) => {
            const product = products.find((p) => p.id == variant.product.id)!;
            return (
              <SwiperSlide key={index}>
                <ProductVariantPhoto
                  loading={loading}
                  index={index}
                  key={variant.id}
                  variant={variant}
                  products={products}
                  alt={variant.title}
                  variants={[
                    variant,
                    ...(
                      (variant.alternativeVariants || product.variants).map(
                        (v) => {
                          return allProducts
                            .find((p) => p.id == v.product.id)
                            ?.variants?.find((vv) => vv.id == v.id);
                        }
                      ) as unknown as ProductVariant[]
                    ).filter(Boolean),
                  ]}
                  isFocused={true}
                >
                  {(variant, sameProduct) => {
                    return (
                      <div className="product-meta" slot="below">
                        <div className="product-title" slot="below">
                          {!sameProduct ? variant.title : product.title}
                        </div>
                        <div className="product-description">
                          {variant.summary}
                        </div>
                      </div>
                    );
                  }}
                </ProductVariantPhoto>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
