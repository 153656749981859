import { mdiClipboard, mdiClipboardOutline, mdiFire } from "@mdi/js";
import "./style.scss";
import { useEffect, useState } from "react";
import { navigate } from "vike/client/router";

export default function Dialog({ name }: { name: string }) {
  const [isOpen, setOpen] = useState(true);

  function close() {
    setOpen(false);
    // @ts-ignore
    navigate(location.pathname);
  }
  useEffect(() => {
    const onOutsideClick = (e) => {
      if (!(e.target as HTMLElement).closest(".dialog")) close();
    };
    if (name == "discount") localStorage.setItem("discount", "true");

    document.body.addEventListener("click", onOutsideClick);
    return () => document.body.removeEventListener("click", onOutsideClick);
  }, []);

  if (!isOpen) return;

  return (
    <>
      <div class="dialog-backdrop" onClick={close}></div>
      <dialog open={true} class="dialog copy content">
        <svg viewBox="0 0 24 24" class="icon">
          <path d={mdiFire} />
        </svg>
        {name == "discount" && (
          <>
            <h2>15% OFF for returning customers</h2>
            <p>
              <span>If you have shopped with us before</span>, you can use the
              code <code>IGNIS15</code> to get <span>15%</span> off on checkout.{" "}
            </p>
            <div class="buttons">
              <button
                class="button"
                onClick={(e) => {
                  try {
                    navigator.clipboard.writeText("IGNIS15");
                    e.currentTarget.blur();
                  } catch (e) {
                    prompt("Copy code to clipboard", "IGNIS15");
                  }
                }}
              >
                Copy code
              </button>
              <button class="button close primary next" onClick={close}>
                Proceed to shop
              </button>
            </div>
          </>
        )}
      </dialog>
    </>
  );
}
