import { mdiChevronDown } from "@mdi/js";
import { animated, useTransition } from "@react-spring/web";
import { useLayoutEffect, useMemo, useState } from "preact/compat";
import Image from "../../lib/image.jsx";
import { Product, ProductVariant } from "../../lib/shopify.js";
import { SlotedChildren, useSlots } from "../../lib/useSlots.js";
import Buy from "../buy/index.jsx";
import "./style.scss";

const defaultThumbnail = {
  url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
  width: 1,
  height: 1,
};
export default function ProductVariantPhoto({
  preloads,
  className,
  index,
  product,
  image,
  isFocused,
  size,
  sizes = "product",
  color,
  variant,
  products,
  onVariantChange,
  alt,
  badge,

  variants,
  children,
  loading,
  withUi,
  withRandom,
  withThumbnail,
  ...props
}: {
  preloads?: {
    width: number;
    height: number;
    url: string;
  }[];
  image?: {
    width: number;
    height: number;
    url: string;
  };
  index: number;
  product?: string;
  isFocused?: boolean | null;
  sizes?: string;
  size?: string;
  color?: string;
  alt?: string;
  products: Product[];
  variants?: ProductVariant[];
  variant: ProductVariant | null;
  children?: SlotedChildren<
    [variant: ProductVariant, sameProduct: boolean],
    "below"
  >;
  onVariantChange?: (variant: ProductVariant | null) => any;
  loading?: string;
  withUi?: boolean;
  withRandom?: boolean;
  withThumbnail?: boolean;
  badge?: string;
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children" | "size" | "loading"
>) {
  const [hydrated, setHydrated] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(
    withRandom
      ? null
      : variants?.find((v) => v.id == variant?.id) || variants?.[0] || variant
  );
  const someVariant = useMemo(
    () => selectedVariant || variants?.[0] || variant!,
    [selectedVariant, variants, variant]
  );
  useLayoutEffect(() => {
    setHydrated(true);
  }, []);

  const ownerProduct = products?.find((p) => p.id == someVariant.product.id);
  let usedImage =
    image ||
    (ownerProduct?.productType == "Candles"
      ? image
      : (selectedVariant || variants?.[0] || someVariant).thumbnails?.[0]) ||
    (variant || someVariant).thumbnails?.[0] ||
    defaultThumbnail;
  const transition = useTransition([usedImage], {
    from: { opacity: 0, top: 0, left: 0, zIndex: 1 },
    initial: () => ({
      position: "absolute",
      opacity: 1,
      zIndex: 0,
    }),
    enter: () => {
      return {
        position: "absolute",
        opacity: 1,
        zIndex: 1,
      };
    },
    update: () => {
      return {
        position: "absolute",
        opacity: 1,
        zIndex: 0,
      };
    },
    leave: () => {
      return {
        position: "absolute",
        opacity: 0,
        zIndex: 2,
      };
    },
    config: {
      tension: 40,
      friction: 40,
    },
    immediate: () => !hydrated,
  });
  const sameProduct =
    variants && variants.length > 0
      ? variants.every((a) => a.product.id == ownerProduct?.id)
      : false;

  const productImage = sameProduct
    ? selectedVariant && selectedVariant != variant
      ? selectedVariant.image
      : null
    : ownerProduct?.images[0];

  badge ||= ownerProduct?.badge || variant?.badge;

  return useSlots(
    children,
    ({ below }) => (
      <>
        <div
          className={`product content ${className} ${
            isFocused == null ? "" : isFocused ? "focused" : "not-focused"
          } product-${ownerProduct?.productType?.toLowerCase()}`}
          style={
            {
              "--image-width": `${usedImage.width}`,
              "--image-height": `${usedImage.height}`,
              "--index": index,
            } as any
          }
          data-product={product}
          data-product-size={size}
          data-product-color={color}
          data-product-variant={someVariant.handle}
        >
          {badge && <div className="product-badge">{badge}</div>}
          <div
            className="card"
            {...props}
            style={{
              aspectRatio: `${usedImage.width} / ${usedImage.height}`,
              // @ts-ignore
              "--aspect-ratio": `${usedImage.width} / ${usedImage.height}`,
            }}
          >
            {transition(
              (style, image) =>
                /* @ts-ignore */
                image && (
                  <animated.div
                    style={style}
                    className="card-photo"
                    key={image.url}
                  >
                    <Image
                      priority={!hydrated && loading == "eager"}
                      loading={loading}
                      className="product-photo"
                      src={image.url}
                      width={image.width}
                      height={image.height}
                      sizes={sizes}
                      alt={alt}
                    />
                  </animated.div>
                )
            )}

            {preloads?.map((preload, index) => (
              <Image
                key={"preload" + index}
                className="card-photo card-preload"
                src={preload.url}
                width={preload.width}
                height={preload.height}
              />
            ))}
          </div>

          <div className="product-options">
            {below}
            <>
              <div className="product-bar">
                {variants && variants.length > 1 && (
                  <label className="product-alternatives">
                    {withThumbnail !== false && productImage ? (
                      <Image
                        className="product-avatar"
                        src={productImage.url}
                        width={productImage.width}
                        height={productImage.height}
                        sizes="avatar"
                      />
                    ) : withRandom && !productImage ? (
                      <svg
                        className="product-avatar"
                        viewBox="-5 -5 37.774 37.774"
                        width="48"
                        height="48"
                      >
                        <path
                          d="M10.398,22.811h4.618v4.964h-4.618V22.811z M21.058,1.594C19.854,0.532,17.612,0,14.33,0c-3.711,0-6.205,0.514-7.482,1.543
		c-1.277,1.027-1.916,3.027-1.916,6L4.911,8.551h4.577l-0.02-1.049c0-1.424,0.303-2.377,0.907-2.854
		c0.604-0.477,1.814-0.717,3.632-0.717c1.936,0,3.184,0.228,3.74,0.676c0.559,0.451,0.837,1.457,0.837,3.017
		c0,1.883-0.745,3.133-2.237,3.752l-1.797,0.766c-1.882,0.781-3.044,1.538-3.489,2.27c-0.442,0.732-0.665,2.242-0.665,4.529h4.68
		v-0.646c0-1.41,0.987-2.533,2.965-3.365c2.03-0.861,3.343-1.746,3.935-2.651c0.592-0.908,0.888-2.498,0.888-4.771
		C22.863,4.625,22.261,2.655,21.058,1.594z"
                        />
                      </svg>
                    ) : null}

                    <span className="product-alternative-title">
                      {sameProduct
                        ? selectedVariant
                          ? selectedVariant.shortTitle || selectedVariant.title
                          : "Random"
                        : ownerProduct?.shortTitle || ownerProduct?.title}
                    </span>
                    <select
                      style={{ opacity: "0.0", position: "absolute" }}
                      value={selectedVariant?.id}
                      onChange={(e) => {
                        const chosenVariant =
                          variants.find(
                            (v) => v.id == (e.target as HTMLSelectElement).value
                          ) || null;

                        setSelectedVariant(chosenVariant);
                        onVariantChange?.(chosenVariant);
                      }}
                    >
                      {withRandom && (
                        <>
                          <option key={"random"} value="">
                            Random
                          </option>
                        </>
                      )}
                      {variants.map((alternative, index) => {
                        return (
                          <option key={index} value={alternative.id}>
                            {sameProduct
                              ? alternative.title
                              : products.find(
                                  (p) => p.id == alternative.product.id
                                )?.title}
                          </option>
                        );
                      })}
                    </select>
                    <svg viewBox="0 0 24 24">
                      <path d={mdiChevronDown} />
                    </svg>
                  </label>
                )}
                {withUi !== false && (
                  <Buy variant={someVariant} allProducts={products} />
                )}
              </div>
            </>
          </div>
        </div>
      </>
    ),
    someVariant,
    sameProduct
  );
}
