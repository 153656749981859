const svgLayout: string = `
<svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M300 290C300 295.523 295.523 300 290 300H10C4.47716 300 0 295.523 0 290V30L21.2132 8.7868C26.8393 3.16071 34.4699 0 42.4264 0H85.9738C94.7289 0 102.983 3.8903 109.121 10.1343C112.264 13.3326 115.788 16.6911 119 19.2327C127.521 25.9748 138.29 30 150 30C161.71 30 172.479 25.9748 181 19.2327C184.212 16.6911 187.736 13.3326 190.879 10.1343C197.017 3.8903 205.271 0 214.026 0H257.574C265.53 0 273.161 3.16071 278.787 8.7868L300 30V290Z" fill="#D9D9D9"/>
<path d="M0 30L21.2132 8.7868C26.8393 3.16071 34.4699 0 42.4264 0H85.9738C94.7289 0 102.983 3.8903 109.121 10.1343C112.264 13.3326 115.788 16.6911 119 19.2327C127.521 25.9748 138.29 30 150 30C161.71 30 172.479 25.9748 181 19.2327C184.212 16.6911 187.736 13.3326 190.879 10.1343C197.017 3.8903 205.271 0 214.026 0H257.574C265.53 0 273.161 3.16071 278.787 8.7868L300 30" stroke="black"/>
<path d="M65.5 0H52.4264C44.4699 0 36.8393 3.16071 31.2132 8.7868L8.78679 31.2132C3.1607 36.8393 0 44.4699 0 52.4264V247.574C0 255.53 3.1607 263.161 8.78679 268.787L31.2132 291.213C36.8393 296.839 44.4699 300 52.4264 300H123M235 0H247.574C255.53 0 263.161 3.1607 268.787 8.78679L291.213 31.2132C296.839 36.8393 300 44.4699 300 52.4264V247.574C300 255.53 296.839 263.161 291.213 268.787L268.787 291.213C263.161 296.839 255.53 300 247.574 300H177" stroke="black"/>
<path d="M149.99 84.9999C109.554 84.9381 75.6311 56.6406 67.0808 18.7684C65.9636 13.8204 61.7581 10 56.6855 10H20C14.4772 10 10 14.4772 10 20V280C10 285.523 14.4443 290 19.9672 290C39.0861 290 82.1625 290 117.642 290C121.314 290 124.578 287.891 126.944 285.082C132.447 278.55 140.74 274.514 149.99 274.5M149.99 84.9999C190.427 84.9388 224.369 56.6411 232.919 18.7684C234.036 13.8204 238.242 10 243.314 10H280C285.523 10 290 14.4772 290 20V280C290 285.523 285.513 290 279.99 290C237.044 290 223.9 290 182.39 290C178.717 290 175.422 287.891 173.056 285.082C167.554 278.549 159.241 274.513 149.99 274.5" stroke="black"/>
<path d="M278.5 288.5C284.023 288.5 288.5 284.023 288.5 278.5V21.5C288.5 15.9772 284.023 11.5 278.5 11.5L243.314 11.5C239.036 11.5 235.367 14.7397 234.382 19.0988C225.678 57.642 191.142 86.4377 149.992 86.4999L149.988 86.4999C108.838 86.437 74.3193 57.6412 65.6176 19.0988C64.6334 14.7397 60.964 11.5 56.6855 11.5L21.5 11.5C15.9772 11.5 11.5 15.9772 11.5 21.5L11.5 278.5C11.5 284.023 15.9771 288.5 21.5 288.5H116.951C120.519 288.5 123.721 286.512 126.055 283.813C131.839 277.124 140.436 273.014 149.988 273H149.992C159.545 273.013 168.155 277.123 173.945 283.815C176.279 286.512 179.481 288.5 183.048 288.5H278.5Z" stroke="black"/>

<path d="M300 290C300 295.523 295.523 300 290 300H10C4.47716 300 0 295.523 0 290V30L25.6066 4.3934C28.4196 1.58035 32.235 0 36.2132 0H105.429C109.806 0 113.95 1.92426 116.949 5.11252C119.783 8.12418 123.481 11.8382 126.75 14.4245C133.141 19.4811 141.218 22.5 150 22.5C158.782 22.5 166.859 19.4811 173.25 14.4245C176.519 11.8382 180.217 8.12419 183.051 5.11253C186.05 1.92426 190.194 0 194.571 0H263.787C267.765 0 271.58 1.58035 274.393 4.3934L300 30V290Z" fill="#D9D9D9"/>
<path d="M0 30L25.6066 4.3934C28.4196 1.58035 32.235 0 36.2132 0H105.429C109.806 0 113.95 1.92426 116.949 5.11252C119.783 8.12418 123.481 11.8382 126.75 14.4245C133.141 19.4811 141.218 22.5 150 22.5C158.782 22.5 166.859 19.4811 173.25 14.4245C176.519 11.8382 180.217 8.12419 183.051 5.11253C186.05 1.92426 190.194 0 194.571 0H263.787C267.765 0 271.58 1.58035 274.393 4.3934L300 30" stroke="black"/>
<path d="M115 0H26.2132C22.235 0 18.4196 1.58035 15.6066 4.3934L4.39339 15.6066C1.58035 18.4197 0 22.235 0 26.2132V273.787C0 277.765 1.58035 281.58 4.39339 284.393L15.6066 295.607C18.4196 298.42 22.235 300 26.2132 300H120M185 0H273.787C277.765 0 281.58 1.58035 284.393 4.39339L295.607 15.6066C298.42 18.4196 300 22.235 300 26.2132V273.787C300 277.765 298.42 281.58 295.607 284.393L284.393 295.607C281.58 298.42 277.765 300 273.787 300H180" stroke="black"/>
<path d="M149.99 35C133.561 34.991 119.772 23.6629 116.013 8.39184C115.405 5.92283 113.305 4 110.763 4H9C6.23857 4 4 6.23858 4 9V291C4 293.761 6.23858 296 9 296H114.635C118.873 296 122.519 293.225 124.832 289.674C130.183 281.457 139.465 276.019 149.99 276M150.01 35C166.438 34.9907 180.228 23.6627 183.987 8.39184C184.595 5.92282 186.695 4 189.237 4H291C293.761 4 296 6.23858 296 9V291C296 293.761 293.761 296 291 296H185.365C181.127 296 177.481 293.225 175.168 289.674C169.816 281.459 160.532 276.021 150.01 276" stroke="black"/>
<path d="M185.444 8.75037C185.915 6.83724 187.497 5.5 189.237 5.5H291C292.933 5.5 294.5 7.06709 294.5 9V291C294.5 292.933 292.933 294.5 291 294.5H185.365C181.812 294.5 178.575 292.156 176.425 288.855C170.807 280.232 161.04 274.522 149.993 274.5H149.987C138.936 274.52 129.191 280.231 123.575 288.855C121.425 292.156 118.188 294.5 114.635 294.5H9.00003C7.06712 294.5 5.50003 292.933 5.50003 291V9C5.50003 7.06701 7.06703 5.5 9.00003 5.5H110.763C112.503 5.5 114.085 6.83731 114.557 8.7505C118.477 24.6761 132.855 36.4906 149.989 36.5H149.991C167.124 36.4903 181.523 24.6769 185.444 8.75037Z" stroke="black"/>

<path d="M300 290C300 295.523 295.523 300 290 300H10C4.47716 300 0 295.523 0 290V66.8925C0 43.2706 9.38376 20.6162 26.087 3.91304V3.91304C28.5924 1.40756 31.9906 0 35.5339 0H112.326H187.674H264.466C268.009 0 271.408 1.40756 273.913 3.91304V3.91304C290.616 20.6162 300 43.2706 300 66.8925V290Z" fill="#D9D9D9"/>
<path d="M4.39339 15.6066L15.6066 4.3934C18.4196 1.58035 22.235 0 26.2132 0H115H185H273.787C277.765 0 281.58 1.58035 284.393 4.39339L295.607 15.6066C298.42 18.4196 300 22.235 300 26.2132V273.787C300 277.765 298.42 281.58 295.607 284.393L284.393 295.607C281.58 298.42 277.765 300 273.787 300H180H120H26.2132C22.235 300 18.4196 298.42 15.6066 295.607L4.39339 284.393C1.58035 281.58 0 277.765 0 273.787V26.2132C0 22.235 1.58035 18.4197 4.39339 15.6066Z" stroke="black"/>
<path d="M115 0H26.2132C22.235 0 18.4196 1.58035 15.6066 4.3934L4.39339 15.6066C1.58035 18.4197 0 22.235 0 26.2132V273.787C0 277.765 1.58035 281.58 4.39339 284.393L15.6066 295.607C18.4196 298.42 22.235 300 26.2132 300H120H180H273.787C277.765 300 281.58 298.42 284.393 295.607L295.607 284.393C298.42 281.58 300 277.765 300 273.787V26.2132C300 22.235 298.42 18.4196 295.607 15.6066L284.393 4.39339C281.58 1.58035 277.765 0 273.787 0H185H115Z" fill="#D9D9D9" stroke="black"/>

</svg>
`;
`
<path d="M300 290C300 295.523 295.523 300 290 300H10C4.47716 300 0 295.523 0 290V30L25.6066 4.3934C28.4196 1.58035 32.235 0 36.2132 0H105.429C109.806 0 113.95 1.92426 116.949 5.11252C119.783 8.12418 123.481 11.8382 126.75 14.4245C133.141 19.4811 141.218 22.5 150 22.5C158.782 22.5 166.859 19.4811 173.25 14.4245C176.519 11.8382 180.217 8.12419 183.051 5.11253C186.05 1.92426 190.194 0 194.571 0H263.787C267.765 0 271.58 1.58035 274.393 4.3934L300 30V290Z" fill="#D9D9D9"/>
<path d="M0 30L25.6066 4.3934C28.4196 1.58035 32.235 0 36.2132 0H105.429C109.806 0 113.95 1.92426 116.949 5.11252C119.783 8.12418 123.481 11.8382 126.75 14.4245C133.141 19.4811 141.218 22.5 150 22.5C158.782 22.5 166.859 19.4811 173.25 14.4245C176.519 11.8382 180.217 8.12419 183.051 5.11253C186.05 1.92426 190.194 0 194.571 0H263.787C267.765 0 271.58 1.58035 274.393 4.3934L300 30" stroke="black"/>
<path d="M115 0H26.2132C22.235 0 18.4196 1.58035 15.6066 4.3934L4.39339 15.6066C1.58035 18.4197 0 22.235 0 26.2132V273.787C0 277.765 1.58035 281.58 4.39339 284.393L15.6066 295.607C18.4196 298.42 22.235 300 26.2132 300H120M185 0H273.787C277.765 0 281.58 1.58035 284.393 4.39339L295.607 15.6066C298.42 18.4196 300 22.235 300 26.2132V273.787C300 277.765 298.42 281.58 295.607 284.393L284.393 295.607C281.58 298.42 277.765 300 273.787 300H180" stroke="black"/>
<path d="M149.99 35C133.561 34.991 119.772 23.6629 116.013 8.39184C115.405 5.92283 113.305 4 110.763 4H9C6.23857 4 4 6.23858 4 9V291C4 293.761 6.23858 296 9 296H114.635C118.873 296 122.519 293.225 124.832 289.674C130.183 281.457 139.465 276.019 149.99 276M150.01 35C166.438 34.9907 180.228 23.6627 183.987 8.39184C184.595 5.92282 186.695 4 189.237 4H291C293.761 4 296 6.23858 296 9V291C296 293.761 293.761 296 291 296H185.365C181.127 296 177.481 293.225 175.168 289.674C169.816 281.459 160.532 276.021 150.01 276" stroke="black"/>
`;

export const paths: string[] = [];
svgLayout.replace(/<path.*?d="([^"]*)"/g, (m, d) => {
  paths.push(d);
  return m;
});

const svgWidgets = `
<path d="M0 280V26.2132C0 22.235 1.58035 18.4196 4.3934 15.6066L15.6066 4.39339C18.4197 1.58035 22.235 0 26.2132 0H60C62.7614 0 65 2.23858 65 5V15C65 17.7614 67.2386 20 70 20H230C232.761 20 235 17.7614 235 15V5C235 2.23858 237.239 0 240 0H273.787C277.765 0 281.58 1.58035 284.393 4.39339L295.607 15.6066C298.42 18.4196 300 22.235 300 26.2132V265C300 273.284 293.284 280 285 280H26.2132C22.2349 280 18.4197 281.58 15.6066 284.393L0 300V280Z" fill="#473D3D"/>
<path d="M300 20V273.787C300 277.765 298.42 281.58 295.607 284.393L284.393 295.607C281.58 298.42 277.765 300 273.787 300H240C237.239 300 235 297.761 235 295V285C235 282.239 232.761 280 230 280H70C67.2386 280 65 282.239 65 285V295C65 297.761 62.7614 300 60 300H26.2132C22.235 300 18.4197 298.42 15.6066 295.607L4.3934 284.393C1.58035 281.58 0 277.765 0 273.787V35C0 26.7157 6.71573 20 15 20H273.787C277.765 20 281.58 18.4197 284.393 15.6066L300 0V20Z" fill="#473D3D"/>

`;
export const widgetPaths: string[] = [];
svgWidgets.replace(/<path.*?d="([^"]*)"/g, (m, d) => {
  widgetPaths.push(d);
  return m;
});

type ParametrizeFunction = (coord: number, axis: "x" | "y") => number;

export function parametrizePath(
  path: string,
  parametrize: ParametrizeFunction
) {
  // Replace each "d" attribute with parametrized coordinates
  const commands = path.match(/[a-zA-Z][^a-zA-Z]*/g) || [];
  let newPathData = "";

  for (const command of commands) {
    const type = command[0];
    const params = command.slice(1).trim().split(/\s+/).map(parseFloat);
    <svg
      width="294"
      height="294"
      viewBox="0 0 294 294"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M113.013 5.39184C116.772 20.6629 130.561 31.991 146.99 32C163.418 31.9907 177.228 20.6627 180.987 5.39184C181.595 2.92282 183.695 1 186.237 1H288C290.761 1 293 3.23858 293 6V288C293 290.761 290.761 293 288 293H182.365C178.127 293 174.481 290.225 172.168 286.674C166.816 278.459 157.512 273.021 146.99 273C136.465 273.019 127.183 278.457 121.832 286.674C119.519 290.225 115.873 293 111.635 293H6C3.23858 293 1 290.761 1 288V6C1 3.23858 3.23857 1 6 1H107.763C110.305 1 112.405 2.92283 113.013 5.39184Z"
        stroke="black"
      />
    </svg>;

    if (type === "Z") {
      <svg
        width="300"
        height="300"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.39339 15.6066L15.6066 4.3934C18.4196 1.58035 22.235 0 26.2132 0H115H185H273.787C277.765 0 281.58 1.58035 284.393 4.39339L295.607 15.6066C298.42 18.4196 300 22.235 300 26.2132V273.787C300 277.765 298.42 281.58 295.607 284.393L284.393 295.607C281.58 298.42 277.765 300 273.787 300H180H120H26.2132C22.235 300 18.4196 298.42 15.6066 295.607L4.39339 284.393C1.58035 281.58 0 277.765 0 273.787V26.2132C0 22.235 1.58035 18.4197 4.39339 15.6066Z"
          stroke="black"
        />
      </svg>;

      newPathData += type;
    } else if (type === "H" || type === "h") {
      params[0] += parametrize(params[0], "x");
      newPathData += `${type}${params.join(" ")} `;
    } else if (type === "V" || type === "v") {
      params[0] += parametrize(params[0], "y");
      newPathData += `${type}${params.join(" ")} `;
    } else {
      for (let i = 0; i < params.length; i++) {
        params[i] += parametrize(params[i], i % 2 === 0 ? "x" : "y");
      }
      newPathData += `${type}${params.join(" ")} `;
    }
  }

  return newPathData.trim();
}

export const backdrop =
  "M 197.7783 94.664 C 198.5852 96.6074 199 98.6903 199 100.7939 C 199 102.8978 198.5852 104.9808 197.7783 106.9242 L 177.0814 156.7855 C 176.2745 158.7289 175.0925 160.495 173.6016 161.982 C 172.1116 163.47 170.3415 164.6491 168.3941 165.4539 C 166.4468 166.2588 164.3589 166.6736 162.2512 166.6736 C 160.1435 166.6736 158.0556 166.2588 156.1082 165.4539 L 106.1439 144.8006 C 104.1966 143.9957 102.1087 143.5809 100.001 143.5809 C 97.8931 143.5809 95.8058 143.9957 93.8583 144.8006 L 43.8918 165.4539 C 41.9443 166.2588 39.857 166.6736 37.749 166.6736 C 35.6411 166.6736 33.5537 166.2588 31.6061 165.4539 C 29.6585 164.6491 27.8889 163.47 26.3983 161.982 C 24.9076 160.495 23.7252 158.7289 22.9184 156.7855 L 2.2219 106.9242 C 1.4152 104.9808 1 102.8978 1 100.7939 C 1 98.6903 1.4152 96.6074 2.2219 94.664 L 26.5179 36.1343 C 28.1466 32.2093 31.271 29.0907 35.2039 27.4645 L 93.8583 3.2193 C 95.8058 2.4143 97.8931 2 100.001 2 C 102.1087 2 104.1966 2.4143 106.1439 3.2193 L 164.7965 27.4645 C 168.7288 29.0907 171.8532 32.2093 173.4818 36.1343 L 197.7783 94.664 Z";
//export const circle = "M0 100A100 100 0 1 0 0 -100A100 100 0 0 0 0 100";
export const circle = "M200,100a100,100 0 1,0 -200,0a100,100 0 1,0 200,0";
// @ts-ignore
import { interpolate } from "flubber";
export const backdropToCircle = interpolate(backdrop, circle, {
  maxSegmentLength: 3,
});

export function logoPath(diameter: number = 1000) {
  const groups = {
    largeTop: [
      "M100 19L106.832 76.5293L100 91.2169L93.1689 76.5293L100 19Z",
      "M157.277 42.7251L121.428 88.2349L106.212 93.7901L111.767 78.574L157.277 42.7251Z",
      "M42.7245 42.7246L78.5733 88.2344L93.7894 93.7896L88.2343 78.5735L42.7245 42.7246Z",
    ],
    smallTop: [
      "M69.003 25.166L89.1301 61.0066L88.6097 72.5009L80.114 64.7413L69.003 25.166Z",
      "M174.835 69.0032L138.994 89.1304L127.5 88.6099L135.26 80.1142L174.835 69.0032Z",
      "M25.1659 69.0032L61.0064 89.1304L72.5007 88.6099L64.741 80.1142L25.1659 69.0032Z",
      "M130.998 25.166L110.871 61.0066L111.392 72.5009L119.887 64.7413L130.998 25.166Z",
    ],
    large: [
      "M181 99.9996L123.471 106.831L108.783 99.9996L123.471 93.1683L181 99.9996Z",
      "M157.276 157.277L111.766 121.428L106.211 106.212L121.427 111.767L157.276 157.277Z",
      "M100 181L106.832 123.471L100 108.783L93.1689 123.471L100 181Z",
      "M42.7245 157.275L88.2343 121.426L93.7895 106.21L78.5734 111.765L42.7245 157.275Z",
      "M19 99.9991L76.5293 106.83L91.2167 99.9991L76.5293 93.1678L19 99.9991Z",
    ],
    center: [
      "M100 94.1443L104.141 95.8593L105.856 99.9997L104.141 104.14L100 101.952L95.8598 104.14L94.1448 99.9997L95.8598 95.8593L100 94.1443Z",
    ],
    small: [
      "M174.834 130.997L135.259 119.886L127.5 111.39L138.994 110.87L174.834 130.997Z",
      "M69.0032 174.834L80.1142 135.259L88.6099 127.499L89.1303 138.994L69.0032 174.834Z",
      "M130.998 174.834L119.887 135.259L111.391 127.499L110.87 138.994L130.998 174.834Z",
      "M25.1666 130.997L64.7418 119.886L72.5014 111.39L61.0072 110.87L25.1666 130.997Z",
    ],
  };
  for (var group in groups) {
    groups[group as keyof typeof groups] = groups[
      group as keyof typeof groups
    ].map((p) => {
      const od = 100;
      const ox = 0;
      const oy = 0;
      return p.replace(
        /(-?\d+\.?(?:\d|e-)*) (-?\d+\.?(?:\d|e-)*)/g,
        (m, x, y) =>
          String(((parseFloat(x) - ox) / od) * diameter) +
          " " +
          String(((parseFloat(y) - oy) / od) * diameter)
      );
    });
  }
  return groups;
}

export function getWidgetPaths({ currency }: { currency: string }) {
  // Function to parametrize coordinates based on x and y values
  const getParametrizer: (
    x1: number,
    x2: number,
    x3: number,
    x4: number,
    y1: number,
    y2: number,
    y3: number
  ) => ParametrizeFunction = (x1, x2, x3, x4, y1, y2, y3) => (coord, axis) => {
    if (axis === "x") {
      if (coord <= 55) {
        return x1;
      } else if (coord <= 225) {
        return x2;
      } else if (coord <= 265) {
        return x3;
      } else {
        return x4;
      }
    } else {
      if (coord <= 75) {
        return y1;
      } else if (coord <= 125) {
        return y2;
      } else {
        return y3;
      }
    }
  };
  const tagWidth = currency == "USD" ? 20 : 0;
  const width = 180;
  const height = 140;
  const bubbleLeft = parametrizePath(
    widgetPaths[1], //isDesktop ? -5 : -15,
    getParametrizer(
      0,
      -30 + tagWidth,
      (width - 300) / 2 - 30 - tagWidth,
      (width - 300) / 2 - 60,
      0,
      0,
      (height - 300) / 2 - 20
    )
  );
  const bubbleRight = parametrizePath(
    widgetPaths[0], //isDesktop ? -5 : -15,
    getParametrizer(
      0,
      -30 + tagWidth,
      (width - 300) / 2 - 30 - tagWidth,
      (width - 300) / 2 - 60,
      0,
      0,
      (height - 300) / 2 - 20
    )
  );
  return {
    bubbleRight,
    bubbleLeft,
  };
}

export function getPathsForSettings({
  width,
  height,
  isDesktop,
  padding,
  borders,
}: {
  width: number;
  height: number;
  isDesktop: boolean;
  padding: number;
  borders: number;
}) {
  // Function to parametrize coordinates based on x and y values
  const getParametrizer: (
    x1: number,
    x2: number,
    x3: number,
    y1: number,
    y2: number,
    y3: number
  ) => ParametrizeFunction = (x1, x2, x3, y1, y2, y3) => (coord, axis) => {
    if (axis === "x") {
      if (coord <= 55) {
        return x1;
      } else if (coord <= 245) {
        return x2;
      } else {
        return x3;
      }
    } else {
      if (coord <= 100) {
        return y1;
      } else if (coord <= 230) {
        return y2;
      } else {
        return y3;
      }
    }
  };

  const originals = paths.slice(isDesktop ? 0 : 5, isDesktop ? 5 : 10);
  const pathShift = isDesktop ? 0 : 1.5;
  const layoutOuterPath = parametrizePath(
    originals[2], //isDesktop ? -5 : -15,
    getParametrizer(
      padding + pathShift,
      width / 2 - 150,
      width -
        300 -
        padding -
        pathShift /* - borders * 2 + (isDesktop ? 5 : 15) */,
      padding + pathShift,
      height - 300 - pathShift /* - borders * 2 */,
      height - 300 - padding - pathShift /* - borders * 2 */
    )
  );
  const layoutPathInner = parametrizePath(
    originals[3], //isDesktop ? -5 : -15,
    getParametrizer(
      padding + pathShift,
      width / 2 - 150,
      width -
        300 -
        padding -
        pathShift /* - borders * 2 + (isDesktop ? 5 : 15) */,
      padding + pathShift,
      height - 300 - padding /* - borders * 2 */,
      height - 300 - padding - pathShift /* - borders * 2 */
    )
  );
  const layoutShape = parametrizePath(
    originals[4], //isDesktop ? -5 : -15,
    getParametrizer(
      padding + pathShift,
      width / 2 - 150,
      width -
        300 -
        padding -
        pathShift /* - borders * 2 + (isDesktop ? 5 : 15) */,
      padding + pathShift,
      height - 300 - padding /* - borders * 2 */,
      height - 300 - padding - pathShift /* - borders * 2 */
    )
  );
  const slideWidth = width - borders * 2;
  const slideOffset = isDesktop ? 5 : 18;
  const slide = parametrizePath(
    originals[0],
    getParametrizer(
      -slideOffset,
      slideWidth / 2 - 150,
      slideWidth - 300 + slideOffset /* - borders * 2  */,
      0,
      height - 300 - borders /* - borders * 2 */,
      height - 300 - borders - padding /* - borders * 2 */
    )
  );

  const footerWidth = Math.min(450, width - borders * 2);
  const footerOffset = Math.min(450 - footerWidth, 20);
  const footer = parametrizePath(
    paths[10],
    getParametrizer(
      1 - footerOffset,
      footerWidth / 2 - 150,
      footerWidth - 300 - 1 + footerOffset /* - borders * 2  */,
      0,
      height - 300 - borders /* - borders * 2 */,
      height - 300 - borders - padding /* - borders * 2 */
    )
  );

  return { layoutShape, layoutOuterPath, layoutPathInner, slide, footer };
}

export function getMediaPaths({
  width,
  height,
  isDesktop,
  padding,
  borders,
}: {
  width: number;
  height: number;
  isDesktop: boolean;
  padding: number;
  borders: number;
}) {
  // Function to parametrize coordinates based on x and y values
  const getParametrizer: (
    x1: number,
    x2: number,
    x3: number,
    y1: number,
    y2: number,
    y3: number
  ) => ParametrizeFunction = (x1, x2, x3, y1, y2, y3) => (coord, axis) => {
    if (axis === "x") {
      if (coord <= 55) {
        return x1;
      } else if (coord <= 245) {
        return x2;
      } else {
        return x3;
      }
    } else {
      if (coord <= 100) {
        return y1;
      } else if (coord <= 230) {
        return y2;
      } else {
        return y3;
      }
    }
  };

  const maskShift = 2.5;
  const mask = parametrizePath(
    paths[11], //isDesktop ? -5 : -15,
    getParametrizer(
      padding + maskShift,
      width / 2 - 150,
      width -
        300 -
        padding -
        maskShift /* - borders * 2 + (isDesktop ? 5 : 15) */,
      padding + maskShift,
      height - 300 - maskShift /* - borders * 2 */,
      height - 300 - padding - maskShift /* - borders * 2 */
    )
  );

  const pathShift = 1;
  const borderOuter = parametrizePath(
    paths[12], //isDesktop ? -5 : -15,
    getParametrizer(
      padding + pathShift,
      width / 2 - 150,
      width -
        300 -
        padding -
        pathShift /* - borders * 2 + (isDesktop ? 5 : 15) */,
      padding + pathShift,
      height - 300 - pathShift /* - borders * 2 */,
      height - 300 - padding - pathShift /* - borders * 2 */
    )
  );

  const borderInner = parametrizePath(
    paths[12], //isDesktop ? -5 : -15,
    getParametrizer(
      padding + pathShift,
      width / 2 - 150,
      width -
        300 -
        padding -
        pathShift /* - borders * 2 + (isDesktop ? 5 : 15) */,
      padding + pathShift,
      height - 300 - pathShift /* - borders * 2 */,
      height - 300 - padding - pathShift /* - borders * 2 */
    )
  );

  return {
    mask,
    borderOuter,
    borderInner,
  };
}
