import { useEffect, useState } from "react";

export default function useWindowSize(
  callback?: ({ width, height }: { width: number; height: number }) => void
) {
  const size = getDimensions();
  const [{ width, height }, setSize] = useState(size);

  function getDimensions() {
    return {
      width: Math.min(
        1440,
        typeof window == "undefined" ? 1200 : window?.innerWidth ?? 1280
      ),
      height: Math.min(
        1200,
        typeof window == "undefined" ? 1080 : window?.innerHeight ?? 1024
      ),
    };
  }

  function onResize(force?: boolean) {
    setSize((size) => {
      const newSize = getDimensions();
      if (
        size.width != newSize.width ||
        size.height != newSize.height ||
        force === true
      ) {
        callback?.(newSize);
        return newSize;
      }
      return size;
    });
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener("orientationchange", onResize);
    document.addEventListener("visibilitychange", onResize);
    onResize(true);
    return () => {
      window.addEventListener("resize", onResize);
      window.removeEventListener("orientationchange", onResize);
      document.removeEventListener("visibilitychange", onResize);
    };
  }, []);

  return { width, height };
}
