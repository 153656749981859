import type { PageContext } from "vike/types";
import type { Store } from "../+store.server.js";
import { Link } from "../../components/link/index.jsx";
import { CartContextProps, useCart } from "../../components/cart/provider.jsx";
import "./style.scss";
import Image from "../../lib/image.jsx";
import Buy from "../../components/buy/index.jsx";
import { formatNumber, formatPrice } from "../../lib/shopify.jsx";
import Region from "../../components/region/index.jsx";
import { useLocale, useLocaleMarket } from "../../lib/pathnames.jsx";
import { Price } from "../../components/price/index.jsx";
import { mdiCheck, mdiGift, mdiMenu } from "@mdi/js";
import * as zaraz from "../../lib/zaraz.jsx";

export function SummaryCheckout(
  pageContext: PageContext,
  cartProps: CartContextProps,
  { market }: { market: string }
) {
  const { collections, products, rates } = pageContext.store as Store;
  const { lines, totalQuantity, cart } = cartProps;
  const { locale } = pageContext;

  const totalRp = lines.reduce((acc, line) => {
    const variant = products
      .map((p) => p.variants.find((v) => v.id == line.merchandise.id))
      .filter(Boolean)[0];
    const product = products.find((p) => p.id == variant.product.id);
    return acc + parseFloat(variant.price.amount) * line.quantity;
  }, 0);

  const discountThreshold =
    market == "Indonesia" ? 3000000 : 300 / rates["USD"];
  const discountFormatted = (
    <Price amount={discountThreshold} format="minimal" />
  );

  const balance = discountThreshold - totalRp;

  const returning = `?return=/${locale}/checkout/`;

  return (
    <>
      <h2 slot="menu" key="menu-1">
        Cart
      </h2>
      <a
        class="cart-status"
        href={`/${locale}/checkout/`}
        slot="tray"
        key="tray"
      >
        <p>
          {totalQuantity == 0 ? (
            <>on {discountFormatted}+ orders</>
          ) : balance <= 0 ? (
            <span class="success">
              <svg viewBox="0 0 24 24">
                <path d={mdiGift} />
              </svg>
              Free shipping
            </span>
          ) : (
            <>
              <Price amount={balance} format="minimal" />
              &nbsp;more to <strong>ship free</strong>
            </>
          )}
        </p>
        <h2>
          {totalQuantity == 0
            ? "Free shipping"
            : totalQuantity +
              " item" +
              (totalQuantity == 1 ? "" : "s") +
              " in cart"}
        </h2>
      </a>

      <Region key="top" slot="tray" />

      <div class="cart-summary">
        <div class={`cart-shipping copy ${balance <= 0 ? "free" : ""}`}>
          <h2>FREE SHIPPING on orders over {discountFormatted}</h2>
          {balance <= 0 ? (
            <p>
              We will ship your order for free anywhere{" "}
              {market == "Indonesia" ? " in Indonesia" : " in the world"}!
            </p>
          ) : (
            <>
              <div class="cart-progress">
                <progress min={0} max={discountThreshold} value={totalRp} />
                <span class="cart-progress-balance">
                  <Price amount={balance} /> to go
                </span>
              </div>
              <p>
                Smart buyers appreciate the simplicity of free shipping. It
                takes the worry away. Perhaps add another item?
              </p>
            </>
          )}
        </div>
        {lines.length > 0 ? (
          <>
            <ul className="cart-lines">
              {lines.map((line) => {
                const variant = products
                  .map((p) =>
                    p.variants.find((v) => v.id == line.merchandise.id)
                  )
                  .filter(Boolean)[0];
                const product = products.find(
                  (p) => p.id == variant.product.id
                );

                return (
                  <li class="cart-line" key={variant.id}>
                    <Image
                      src={
                        variant.thumbnails?.[0]?.url ||
                        product?.image?.url ||
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      }
                      width="200"
                      height="200"
                      sizes="avatar"
                    />

                    <div class="cart-meta">
                      <h2>{product.title}</h2>
                      <h3>{line.merchandise.title}</h3>
                    </div>

                    <Buy
                      style="regular"
                      variant={variant}
                      allProducts={products}
                    />
                  </li>
                );
              })}
            </ul>
            {(locale == "en" || locale.endsWith("US")) && (
              <>
                <div class="cart-total">
                  Total in USD:
                  <span>
                    <Price amount={totalRp} />
                  </span>
                </div>
                <div class="cart-conversion ">
                  <div class="content copy">
                    <p>
                      We are based in <span class="id-flag">Indonesia</span>.
                      The card will be charged in equivalent amount in
                      Indonesian Rupiah. Thank you for understanding.
                    </p>
                  </div>
                </div>
                <div class="cart-total">
                  USD/IDR rate:{" "}
                  <span>{formatNumber(Math.floor(1 / rates["USD"]))} IDR</span>
                </div>
              </>
            )}
            <div class="cart-total">
              Total charged:{" "}
              <span>{formatNumber(Math.floor(totalRp))} IDR</span>
            </div>
            {cart && (
              <div class="cart-actions">
                <a
                  href={cart.checkoutUrl}
                  class="button primary next large"
                  onPointerDown={(e) => {
                    const variants = lines
                      .map((l) => {
                        return products
                          .map((p) =>
                            p.variants.find((v) => v.id == l.merchandise.id)
                          )
                          .filter(Boolean)[0];
                      })
                      .filter(Boolean);
                    zaraz.ecommerce("Checkout Started", {
                      products: zaraz.getProducts(variants, products),
                    });
                  }}
                >
                  Continue to payment step
                </a>
              </div>
            )}
          </>
        ) : (
          <div class="copy cart-empty-state">
            <h2>Your cart is empty</h2>
            <p>Try browsing our store for items.</p>
            <p>
              <a href={`/${locale}/onyx/`}>Onyx jars</a> are the best sellers.
            </p>
          </div>
        )}
        <div class="content copy cart-articles">
          <h2>Related articles</h2>
          <ul>
            {market == "Indonesia" ? (
              <>
                <li>
                  <a href={`/${locale}/qna/buying-in-indonesia${returning}`}>
                    Shipping within Indonesia
                  </a>
                </li>
                <li>
                  <a href={`/${locale}/qna/delivery-in-bali/${returning}`}>
                    Delivery in Bali
                  </a>
                </li>
              </>
            ) : (
              <li>
                <a href={`/${locale}/qna/worldwide-shipping/${returning}`}>
                  Shipping worldwide
                </a>
              </li>
            )}
            <li>
              <a href={`/${locale}/qna/returning-products/${returning}`}>
                Return policy
              </a>
            </li>
            <li>
              <a href={`/${locale}/qna/discounts-offers/${returning}`}>
                Sales & Discounts
              </a>
            </li>
            <li class="more">
              <a class="button next" href={`/${locale}/qna/${returning}`}>
                See all articles
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
