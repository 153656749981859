import { PageContext } from "vike/types";
import type { Store } from "../+store.server.js";
import logo from "../../components/logo.jsx";
import { GraphicsMouseScroll } from "../../lib/graphics.jsx";
import Image from "../../lib/image.jsx";

export function SummaryHome(props: PageContext) {
  const { pages } = props.store as Store;
  const page = pages.find((p) => p.handle == "homepage")!;
  const landscape =
    page.images?.find((b) => b.url.includes("Landscape")) ||
    page.images
      ?.slice()
      .sort((b, a) => a.width / a.height - b.width / b.height)[0];
  var portrait =
    page.images?.find((b) => b.url.includes("Portrait")) ||
    page.images
      ?.slice()
      .sort((a, b) => a.width / a.height - b.width / b.height)[0];
  return (
    <>
      <div slot="menu" className="smallLogo">
        {logo}
        <div className="tagline">
          <span>Precious</span> <span>Crystal</span> <span>Candles</span>
        </div>
      </div>
      <div
        className="top-screen screen homescreen fullscreen copy"
        style={{
          /*@ts-ignore */
          "--slide-collapsed-offset": "0px",
          "--slide-current-offset": "0px",
          "--slide-focused-offset": "0px",
          "--slide-intro-offset": "0px",
          "--slide-effective-max-height": "0px",
          "--target-scale": "1",
        }}
      >
        {landscape && portrait && (
          <Image
            breakpoints={[
              {
                media: "(orientation: landscape), (min-width: 800px)",
                src: landscape.url,
                width: landscape.width,
                height: landscape.height,
              },
              {
                media: "(orientation: portrait) and (max-width: 799px)",
                src: portrait.url,
                width: portrait.width,
                height: portrait.height,
              },
            ]}
            sizes="background"
            className="background"
            media="(orientation: landscape)"
            src={portrait.url}
            width={portrait.width}
            height={portrait.height}
            loading={"eager"}
            priority
          />
        )}
      </div>
      <GraphicsMouseScroll />
    </>
  );
}
