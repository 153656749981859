import { formatPrice } from "../../lib/shopify.jsx";

export function Price({
  amount,
  currencyCode = "IDR",
  format = "regular",
}: {
  amount: number;
  currencyCode?: string;
  format?: "regular" | "minimal";
}) {
  return (
    <>
      {formatPrice({ amount: String(amount), currencyCode }, undefined, format)}
    </>
  );
}
